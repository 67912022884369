.header {
    width: 100%;
    height: 80px;
    line-height: 80px;
}

.logo img {
    width: 50%;
}
.menu {
    margin-bottom: 0;
}

.nav__item a {
    text-decoration: none;
    color: var(--heading-color);
    font-weight: 500;
    font-size: 1.1rem;
}

.nav__item a:hover {
    color: inherit;
}

.nav__item a.active__link {
    color: var(--secondary-color);
}

.mobile__menu {
    font-size: 1.3rem;
    color: var(--heading-color);
    display: none;
}

.sticky__header {
    width: 100%;
    height: 80px;
    line-height: 80px;
    background: #fff;
    position: sticky;
    top: 0;
    left: 0;
    box-shadow: 3px 3px 8px -3px #ddd;
    z-index: 999;
}

/* ============== Responive v1 - havent doen front-end in a fucking while ============== */

@media only screen and (max-width:992px) {
    .logo img {
        width: 50%;
    }

    .navigation {
        width: 100%;
        height: 100%;
        position: fixed;
        top: 0;
        left: 0;
        background: rgba(0,0,0,0.411);
        z-index: 999;
        display: none;
    }

    .menu {
        position: absolute;
        top: 0;
        right: 0;
        width: calc(100% - 75%);
        height: 100%;
        background: #fff;
        flex-direction: column;
        line-height: 0.5rem;
        justify-content: center;
        z-index: 9999;
    }

    .show__menu {
        display: block;
    }

    .mobile__menu {
        display: block;
    }
}

@media only screen and (max-width:768px) {
    .logo img {
        width: 60%;
    }

    .menu {
        width: calc(100% - 50%);
    }
}