.search__bar {
  padding: 0.5rem;
  border-radius: 50px;
  box-shadow: rgba(17, 12, 64, 0.15) 0px 48px 100px 0px;
  width: max-content;
  margin-top: 50px;
}

.form__group span {
  font-size: 1.8rem;
  color: #ee6e6e;
}

.form__group input {
  border: none;
  font-size: 0.9rem;
  color: var(--heading-color);
  font-weight: 500;
}

.form__group input:focus {
  outline: none;
}

.form__group h6 {
  margin-bottom: 0;
}

.form__group-fast {
  border-right: 2px solid #ddd;
}

.search__icon i {
  font-size: 1.6rem;
  padding: 0.5rem;
  background: var(--heading-color);
  color: #fff;
  border-radius: 15px 10px 15px 10px;
  cursor: pointer;
}

@media only screen and (max-width: 992px) {
  .search__bar {
    width: 100%;
    /* margin-top: 20px; */
    border-radius: 2rem;
  }

  .search__bar form {
    flex-wrap: wrap;
    /* gap: 1rem; */
  }

  /* .search__bar form .form__group {
        width: 48px;
    } */

  /* .search__bar .form__group {
      flex: 1 1 100%;
      display: flex;
      align-items: center;
      gap: 1rem;
      border-right: none;
      border-bottom: 1px solid #ddd;
    } */

  /* .form__group-last {
      border-bottom: none;
    } */

  /* .search__icon {
      flex: 0 1 auto;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
    } */
}

@media only screen and (max-width: 576px) {
    .search__bar form .form__group {
        width: 100%;
    }

    .form__group input {
        font-size: 0.7rem;
    }

    .form__group span i {
        font-size: 1.1rem;
    }

    .form__group h6 {
        font-size: 1rem;
    }
}